import styled from 'styled-components'

export const InputDiv = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin: 0 0.2em;
`

export const InputName = styled.input.attrs({
	type: "text",
	defaultValue: "",
	autoComplete: "off",
	placeholder: "Name",
})`
	outline-color: #222222;
	transition: 300ms box-shadow ease-in-out;
	transition: 300ms border ease-in-out;
	border-radius: 2px;
	width: 100%;
	border: 0px solid white;
	padding: 0.2em;
	margin-right: 0.3em;
	background: #E5E5E5;
`
export const InputEmail = styled.input.attrs({
	type: "email",
	defaultValue: "",
	autoComplete: "new-password",
	placeholder: "Email",
})`
	outline-color: #222222;
	transition: 500ms box-shadow ease-in-out;
	transition: 300ms border ease-in-out;
	border-radius: 2px;
	width: 100%;
	padding: 0.2em;
	border: 0px solid white;
	background: #E5E5E5;
`

export const InputMsg = styled.textarea.attrs({
	type: "text",
	defaultValue: "",
	placeholder: "Message",
})`
	outline-color: #222222;
	transition: 300ms box-shadow ease-in-out;
	transition: 300ms border ease-in-out;
	border-radius: 2px;
	width: 100%;
	padding: 0.2em;
	margin: 0.2em;
	margin-top: 0.45em;
	border: 0px solid white;
	height: 10em;
	background: #E5E5E5;

	@media (min-width: 480px) {
	height: 15em;
	}
`

export const BtnDiv = styled.div`
	margin: 0.2em;
	width: 100%;
	display: flex;
	justify-content: flex-end;
`
export const InputBtn = styled.input`
	outline-color: #222222;
	border-radius: 2px;
	padding: 0.5em 2em;
	background: ${({ theme }) => theme.accent};
	color: white;
	border: 1px solid ${({ theme }) => theme.accent};
	transition: 500ms all ease-in-out;
	font-size: 1rem;

	${({ disabled }) => (disabled) ? 'background: white' : ''};
	${({ disabled, theme }) => (disabled) ? 'color: ' + theme.accent : ''};
	${({ disabled, theme }) => (disabled) ? 'border: 1px solid' + theme.accent : ''};

	@media (min-width: 720px) {
		font-size: calc(1rem + 0.2vmin);
	}
`

export const ValidateAlert = styled.p`
	font-size: 0.8rem;
	color: #FF350F;
	margin-bottom: 0.5em;
`

export const SucessAlert = styled.p`
	font-size: 1rem;
	color: #459989;
	display: flex;
	justify-content: flex-end;
`