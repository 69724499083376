import React from 'react';
import image from '../../img/contactbg.png';
import Form from './form'
import {
	ContactContainer,
	ContactBg,
	ImgBg,
	ContactContents,
	ContactTextWrapper,
	ContactH1,
	ContactP,
	FormWrapper,
} from './ContactElements'

const Contact = () => {

	return (
		<ContactContainer id="contact">
			<ContactBg>
				<ImgBg src={image} type='image/png' />
			</ContactBg>
			<ContactContents>
				<ContactTextWrapper>
					<ContactH1>Solve With Us</ContactH1>
					<ContactP>Need to Optimize your Business?</ContactP>
					<ContactP>Reach us out!</ContactP>
				</ContactTextWrapper>
				<FormWrapper>
					<Form />
				</FormWrapper>
			</ContactContents>
		</ContactContainer>
	)
}

export default Contact;
