import styled from 'styled-components'
import { Link } from 'react-scroll'

export const Button = styled(Link)`
	border-radius: 2px;
	background-color: ${({ theme }) => theme.accent};
	border: 2px solid ${({ theme }) => theme.accent};
	color: white;
	padding: 1em 0.425em;
	white-space: nowrap;
	font-size: 0.625rem;
	cursor: pointer;
	text-align: center;
	max-width: 80px;
	transition: 400ms all ease-in-out;

	&:hover {
		background-color: white;
		color: ${({ theme }) => theme.accent};
		border: 2px solid  ${({ theme }) => theme.accent};
	}

	@media (min-width:480px) {
		font-size: 1.2rem;
		max-width: 200px;
	}

	@media (min-width:768px) {
		font-size: 1.6em;
		max-width: 250px;
	}

	//for vertical responsiveness
	@media (max-height:480px) {
		font-size: 0.625rem;
		max-width: 80px;
	}
`