import styled from 'styled-components'
import {
	TopLine,
	Heading,
	ServiceP,
} from '../Services/ServiceElements'

export const ContactContainer = styled.section`
	z-index: 1;
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	min-width: 240px;

	:before {
		content: '';
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		transition: 500ms background ease-in-out;
		background: ${({ theme }) => (theme.name === 'light') ? 'rgba(255, 255, 255, 0.95)' : 'rgba(41, 41, 41, 0.95)'};
	}

`
export const ContactBg = styled.div`
	z-index: 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
`

export const ImgBg = styled.img`
	min-width: 100%;
	background: #000;
	background-size: cover;
`

export const ContactContents = styled.article`
	display: grid;
	justify-content: space-evenly;
	grid-template-columns: auto;
	padding: 3em 0;
	min-height: 60vh;

	@media (min-width: 720px) {
	grid-template-columns: auto auto;
	}

	@media (min-width: 1280px) {
		grid-template-columns: auto 500px;
	}
`
export const ContactTextWrapper = styled.div`
	z-index: 9;
	padding: 1em;
`
export const FormWrapper = styled.div`
	z-index: 9;
	min-height: 100px;
	padding: 0.8em;
`

export const ContactTopLine = styled(TopLine)`
	transition: 500ms color ease-in-out;
	text-align: left;
	font-size: 0.6rem;
	margin: 0.2em 0;

	@media (min-width: 480px) {
		font-size: calc(0.5vw + 0.5rem);
	}
`

export const ContactH1 = styled(Heading)`
	text-align: left;
	text-transform: uppercase;
	transition: 500ms color ease-in-out;
	color: ${({ theme }) => theme.accent};
	margin: 0.2em 0;
	padding-bottom: 0.5em;

	@media (min-width: 480px) {
		font-size: calc(0.5vw + 1.5rem);
	}
`

export const ContactP = styled(ServiceP)`
	text-align: left;
	transition: 500ms color ease-in-out;
	margin: 0;
	line-height: 0.8em;
`