import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'

export const Nav = styled.nav`
	background: ${({ scrollNav, theme }) => (scrollNav ? theme.navBg : '0.75')} ;
	color: ${({ scrollNav, theme }) => (scrollNav ? theme.p : theme.heading)};
	opacity: ${({ scrollNav }) => (scrollNav ? '1' : '0.99')} ;
	height: ${({ scrollNav }) => (scrollNav ? 'max(9vh, 80px)' : '16vh')} ;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 240px;
	letter-spacing: 0.2em;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	transition: 800ms all ease;

`

export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 1;
	width: 100%;
	padding: 0 2em;
`

export const NavLogo = styled(LinkR)`
	cursor: pointer;
	margin-right: auto;
	margin-left: 1em;
	max-width: min(25vw, 13em);
	min-width: 150px;
	text-decoration: none;
`

export const NavLineBar = styled.div`
		display: block;
		min-width: min(40px, 10vw);
		background: transparent;
		cursor: pointer;

	@media screen and (min-width: 768px) {
		display: none;
	}
`
export const NavMenu = styled.ul`
	display: none;

	@media screen and (min-width: 768px) {
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;
	margin-right: -22px;
	}
`
export const NavItem = styled.li`
	height: 8vh;
`
export const NavLinks = styled(LinkScroll).attrs({
	smooth: true,
	duration: 500,
	spy: true,
	exact: 'true',
	offset: -80,
})`
	font-family: Oswald;
	font-style: normal;
	font-weight: normal;
	font-size: 1.1rem;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	transition: 100ms all ease-in-out;

	&.active {
		color: ${({ theme }) => theme.accent};
	}

	&:hover {
		color: ${({ theme }) => theme.accent};
	}
`

export const Img = styled.img`
	width: 100%;
	margin: 10px 0 10px 0;
	padding-right: 0;
	max-width: 125px
`

export const ThemeToggle = styled.a`
	margin: 1em;
	min-width: 40px;
`