import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";
import keys from './clientKeys';
import {
	InputDiv,
	InputName,
	InputEmail,
	InputMsg,
	BtnDiv,
	InputBtn,
	ValidateAlert,
	SucessAlert,
} from './FormElements'

const Form = () => {
	const [emailStat, setEmailStat] = useState({ color: '', message: '' });
	const [isSubmit, setIsSubmit] = useState(false);
	const [isCaptchaPass, setIsCaptchaPass] = useState(false);
	const { register, handleSubmit, formState: { errors } } = useForm();
	const { serviceId, templateId, userId, GRECAPTCHA_CLIENT_KEY } = keys

	//form submit handler
	const onSubmit = data => {
		var templateParams = {
			user_name: data.user_name.trim(),
			user_email: data.user_email.trim(),
			message: data.message.trim(),
		}
		setIsSubmit(true)
		sendEmail(templateParams)
		templateParams = {}
	}

	//captcha onchange
	function onChange() {
		setIsCaptchaPass(true)
		setEmailStat({ color: "#459989", message: '' })
	}

	const sendEmail = (emailParams) => {
		if (isCaptchaPass) {
			emailjs.send(serviceId, templateId, emailParams, userId)
				.then((response) => {
					setEmailStat({ color: "#459989", message: 'Email sent!' })
					console.log('EMAIL SENT!', response.status, response.text)
				}, (error) => {
					console.log('EMAIL FAILED...', error)
					setIsSubmit(false)
					if (error.text === "The g-recaptcha-response parameter not found") return setEmailStat({ color: "#FF350F", message: 'Please confirm if you\'re a human.' })
					setEmailStat({ color: "#FF350F", message: 'There was a problem sending email. You can directly email us at contact@fourcorex.com' })
				});

		} else {
			setIsSubmit(false)
			setEmailStat({ color: "#FF350F", message: 'Please confirm if you\'re a human.' })
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} id="contact-form">
			{errors.user_name && <ValidateAlert>{errors.user_name.message}</ValidateAlert>}
			{errors.user_email && <ValidateAlert>{errors.user_email.message}</ValidateAlert>}
			{errors.message && <ValidateAlert>{errors.message.message}</ValidateAlert>}
			<InputDiv>
				<InputName
					style={errors.user_name && { boxShadow: "0 0 4px 1px #FF350F", borderLeft: "5px solid #FF350F" }}
					{...register("user_name", {
						required: "*Name is required.",
						maxLength: { value: 40, message: "*Name must be less than 40 characters." },
						minLength: { value: 3, message: "*Name must be at least 3 characters." },
						pattern: { value: /[a-zA-Z][a-zA-Z ]+/, message: "*Name must have alphabetic characters." }
					})} />
				<InputEmail
					style={errors.user_email && { boxShadow: "0 0 4px 1px #FF350F", borderLeft: "5px solid #FF350F" }}
					{...register("user_email", {
						required: "*Email is required.",
						pattern: { value: /^[^\s@]+@[^\s@]+$/, message: "*Invalid email address." }
					})} />
			</InputDiv>

			<InputMsg
				style={errors.message && { boxShadow: "0 0 4px 1px #FF350F", borderLeft: "5px solid #FF350F" }}
				{...register("message", {
					required: "*Please enter your message.",
					minLength: { value: 10, message: "*Messages must be at least 10 characters." },
				})} />

			<div style={{ display: "flex", justifyContent: "flex-end", marginRight: "-0.3em", }}>
				<ReCAPTCHA sitekey={GRECAPTCHA_CLIENT_KEY} onChange={onChange} />
			</div>

			<BtnDiv>
				<InputBtn type="submit" id="contact-submit" disabled={(Object.keys(errors).length || isSubmit)} />
			</BtnDiv>
			<SucessAlert style={{ color: emailStat.color }}>{emailStat.message}</SucessAlert>

			<input  //this input tag disables autocomplete issue on some browsers
				type="text"
				autoComplete="on"
				value=""
				style={{ display: 'none', opacity: 0, position: 'absolute', left: '-100000px' }}
				readOnly={true}
			/>
		</form>
	);
}

export default Form;