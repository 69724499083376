import styled from 'styled-components'

export const CardContent = styled.div`
	z-index: 2;
	position: relative;
	padding: 0 2.5em;
	min-width: 200px;
	justify-content: space-around;
`

export const CardsWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: stretch; 
	justify-content: space-around;
	margin: 0 0;
	padding-bottom:50px;
	padding-left: 1em;
	padding-right: 1em;
`

export const ProjCard = styled.div`
	margin: 1em auto;
	padding: 1.5em 1.5em;
	border-radius: 2px;
	text-decoration: none;
	box-shadow: 0 1px 3px rgba(0,0,0,0.2);
	transition: 500ms all ease-in-out;
	
	max-width: 300px;
	min-height: 300px;
	overflow:hidden;
	height:calc(100%);
	background: ${({ theme }) => theme.bg};
	
	&:hover {
		transform: scale(1.08);
	}
`

export const ProjTitle = styled.h2`
	color: ${({ theme }) => theme.p};
	transition: 500ms color ease-in-out;
	font-size: 1rem;
	padding: 0.5em 0;

	@media (min-width: 768px) {
	font-size: 1.15rem;
	}

	@media (min-width: 1000px) {
	font-size: 1.4rem;
	}
`

export const ProjDesc = styled.p`
  display: flex;
	color: ${({ theme }) => theme.p};
	transition: 500ms color ease-in-out;
	font-size: 0.8rem;
	padding-bottom: 1rem;
	
  overflow-wrap: break-word;
  word-wrap: break-word;

	@media (min-width: 768px) {
		font-size: 0.9rem;
	}

	@media (min-width: 1000px) {
	font-size: 1.05rem;
	}
`
export const CardButton = styled.button`
	color: ${({ theme }) => theme.p};
	transition: 500ms color ease-in-out;
	font-size: 1rem;
	padding: 0.5em 2em;
  border-radius: 4px;
  border: none;
	position:relative;
	display:inline-block;
	background:${({ theme }) => theme.accent};
  display: flex;
	align-items: stretch; 
	justify-content: space-around;
`

export const CardSvg = styled.svg`
	#windows path {
		transition: 800ms fill ease-in-out;
		fill: ${({ theme }) => theme.bg};
	}

	#accent path {
		transition: 800ms fill ease-in-out;
		fill: ${({ theme }) => theme.accent};
	}
`