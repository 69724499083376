import styled, { css } from 'styled-components'

export const HeroContainer = styled.div`
	background: #000;
	display: flex;
	height: 100vh;
	width: 100%;
	min-width: 240px;
	min-height: 480px;
	position: relative;
	z-index: 2;

	:before {
		content: '';
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: 
			linear-gradient(
				180deg, 
				rgba(255, 115, 2, 0.43) 0%, 
				rgba(0, 0, 0, 0.1806) 100%);
			
		${({ theme }) =>
		!(theme.accent === '#FF7302') &&
		css`
			background: 
				linear-gradient(
					180deg, 
					rgba(69, 153, 137, 0.23) 0%, 
					rgba(0, 0, 0, 0.1806) 100%);
		`}
	}
`

export const HeroBg = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
`

export const VideoBg = styled.video`
	object-fit: cover;
	min-width: 100%;
	min-height: 100vh;
	background: #000;
	background-size: cover;
`

export const HeroBigLogo = styled.div`
	position: absolute;
	opacity: 0.7;
	top: 0;
	width: 1500%;
	margin-top: calc(40vh + 35%);
	margin-left: calc(-50vw + 10%);

	@media (min-width: 768px) {
		top: 0;
		left: 0;
		width: 12864px;
		margin-top: calc(30vh + 40%);
		margin-left: calc(-20vh + 5%);
	}

	@media (min-width: 1080px) {
		top: 0;
		left: 0;
		width: 15360px;
		margin-top: calc(-40vh + 5%);
		margin-left: calc(20vh + 11%);
	}
`

export const HeroContent = styled.div`
	z-index: 3;
	display: flex;
	margin-top: 5em;
	margin-left: 1em;
	flex-direction: column;
	max-width: 20em;
	padding: 0.5em 2.5em;

	@media (min-width: 768px) {
		max-width: 40em;
		margin-left: 4em;
	}

	@media (min-height: 480px) {
		margin-top: 6em;
	}
	@media (min-height: 768px) {
		margin-top: 10em;
	}
	@media (min-height: 1080px) {
		margin-top: 20em;
	}
`

export const TechContent = styled.div`
position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 3;

	backdrop-filter: blur(10px);
	background: rgba(255, 255, 255, 0.82);
	transition: 500ms background ease-in-out;

	${({ theme }) =>
		!(theme.accent === '#FF7302') &&
		css`
			background: rgba(34, 34, 34, 0.74);
		`}
`

export const HeroH1 = styled.h1`
	color: white;
	font-family: Oswald;
	font-style: normal;
	font-weight: normal;
	letter-spacing: 0.2rem;
	text-align: left;
	text-transform: uppercase;
	font-size: calc(0.5vmin + 1.4rem );
	text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.75);

	@media (min-width: 768px) {
		font-size: 3rem;
	}
	@media (min-width: 1024px) {
		font-size: 4rem;
	}

	//for vertical resposiveness
	@media (max-height: 480px) {
		font-size: 1.5rem;
	}
`

export const HeroP = styled.p`
	color: #C4C4C4;
	text-align: left;
	margin: 1.4em 0;
	max-width: 15em;
	font-size: 0.8rem;

	@media (min-width: 480px) {
		font-size: 1rem;
	}

	@media (min-width: 768px) {
		font-size: 1.6rem;
		max-width: 20em;
	}

	@media (max-height: 480px) {
		font-size: 0.8rem;
	}
`