import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
	CardContent,
	CardsWrapper,
	ProjCard,
	ProjTitle,
	ProjDesc,
} from './CardElements'
import {
	CityIcon,
	ExamIcon,
	TicketIcon,
	FinancialIcon,
	EnrollmentIcon
} from './Svg'
import { projectsObj } from './data'


export default class Card extends React.PureComponent {

	componentDidMount() {
		let slickListDiv = document.getElementsByClassName('slick-list')[1]
		slickListDiv.addEventListener('wheel', event => {
			event.preventDefault()
			event.deltaY > 0 ? this.slider.slickNext() : this.slider.slickPrev()
		})
	}

	render() {
		//slider settings
		const settings = {
			dots: true,
			lazyLoad: 'ondemand',
			infinite: true,
			speed: 500,
			swipeToSlide: true,
			autoplay: true,
			autoplaySpeed: 5000,
			pauseOnHover: true,
			centerMode: true,
			slidesToShow: 5,
			responsive: [
				{
					breakpoint: 5000,
					settings: {
						slidesToShow: 5,
						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 2400,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 1700,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						initialSlide: 2,
						centerMode: false,
					}
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerMode: false,
					}
				}
			]
		};
		return (
			<CardContent>
				<Slider style={{ outline: "none" }} ref={slider => this.slider = slider} {...settings}>
					{projectsObj.map(card => {
						return (
							<CardsWrapper key={card.uid}>
								<ProjCard ariaLabel={card.ariaLabel}>
									<div>
										{(card.icon === "cityIcon") && <CityIcon />}
										{(card.icon === "examIcon") && <ExamIcon />}
										{(card.icon === "ticketIcon") && <TicketIcon />}
										{(card.icon === "financialIcon") && <FinancialIcon />}
										{(card.icon === "enrollmentIcon") && <EnrollmentIcon />}
									</div>
									<ProjTitle>{card.h2}</ProjTitle>
									<ProjDesc>{card.desc}</ProjDesc>
								</ProjCard>
							</CardsWrapper>
						)
					})}
				</Slider>
			</CardContent>
		)
	}
}
