import React, { useState } from 'react'

import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import Theme from '../theme'

import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Hero from '../components/Hero'
import Services from '../components/Services'
import Portfolio from '../components/Portfolio'
import Contact from '../components/Contact'
import TechLogos from '../components/TechLogos'
import Footer from '../components/Footer'

const HomeContainer = styled.div`
	@media (max-width: 320px) {
		min-width: 320px;
	}
`

const Home = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [theme, setTheme] = useState('orangedark');

	//toggle sidebar
	const toggle = () => {
		setIsOpen(!isOpen)
	}

	const toggleTheme = () => {
		if (theme === 'orangedark') return setTheme('dark');
		if (theme === 'dark') return setTheme('light');
		if (theme === 'light') return setTheme('orangedark');
	}
	return (
		<ThemeProvider theme={Theme[theme]}>
			<HomeContainer>
				<Sidebar isOpen={isOpen} toggle={toggle} />
				<Navbar toggle={toggle} toggleTheme={toggleTheme} />
				<Hero />
				<TechLogos />
				<Services />
				<Portfolio />
				<Contact />
				<Footer />
			</HomeContainer>
		</ThemeProvider>
	);
}

export default Home;