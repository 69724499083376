import styled from 'styled-components'

export const ServicesContainer = styled.section`
	z-index: 2;
	position: relative;
	color: #000;
	background: ${({ theme }) => theme.bg};
	opacity: 90%;
	transition: 500ms background ease-in-out;
`
export const HeadingWrapper = styled.article`
	padding: 0 1em;
	padding-top: 4em; 
	display: flex;
	justify-content: center;
`

export const ServicesWrapper = styled.article`
	display: grid;
	z-index: 1;
	width: 100%;
	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 1.4em;
	justify-content: center;
	
	@media (min-width: 768px) {
	}
`

export const ServicesRow = styled.div`
	display: grid;
	padding-bottom: 3em;
	align-items: center;
	grid-template-areas: ${({ imgStart }) => (imgStart ? `'img txt'` : `'txt img'`)};
	
	@media screen and (min-width: 480px) {
	padding: 0 2em;	
	}
	@media screen and (max-width: 768px) {
		grid-template-areas: 'img' 'txt';
		margin-bottom: 1em;
	}
`

export const TextWrapper = styled.div`
	max-width: 540px;
	padding-top: 0;
	padding-bottom: 60px;
`

export const TopLine = styled.p`
	color: ${({ theme }) => theme.p};
	text-align: center;
	font-style: normal;
	font-weight: bold;
	letter-spacing: 0.3em;
	text-transform: uppercase;
	line-height: 1em;
	margin-bottom: 1em;
	font-size: 0.69rem;
	transition: 500ms color ease-in-out;

	@media (min-width: 480px) {
	font-size: calc(0.2vw + 1rem);
	letter-spacing: 0.5em
	}
`

export const HSpan = styled.span`
	font-size: 1.4rem;
	letter-spacing: 0.04em;
	line-height: 1.1;
	font-weight: 600;
	color: ${({ theme }) => theme.accent};
	transition: 500ms color ease-in-out;

	@media (min-width: 480px) {
		font-size: 2.3rem;
	}
`

export const Heading = styled.p`
	font-family: Oswald;
	max-width: 440px;
	margin: 0 auto;
	font-size: 1.2rem;
	line-height: 1.2em;
	text-align: center;
	color: ${({ theme }) => theme.p};
	transition: 500ms color ease-in-out;

	@media (min-width: 480px) {
	font-size: 1.7rem;
	}
`

export const TextCol = styled.div`
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: txt;
`

export const ServiceTitle = styled.h1`
	text-transform: uppercase;
	font-style: normal;
	font-weight: bold;
	letter-spacing: 0.05em;
	padding-bottom: 0.5em;
	transition: 500ms color ease-in-out;
	color: ${({ theme }) => theme.accent};
	font-size: calc(0.7vw + 1.1rem);

	@media (min-width: 480px) {
		font-size: calc(1vw + 1.4rem);
	}
`

export const ServiceP = styled.p`
	color: ${({ theme }) => theme.p};
	transition: 500ms color ease-in-out;
	padding-bottom: 0.4em;
	font-style: normal;
	font-weight: normal;
	font-size: calc(0.8vw + 0.65rem);
	max-width: max(85%, 15em);

	@media screen and (min-width: 480px) {
		font-size: calc(0.7vw + 0.75rem);
	}
`

export const ImageCol = styled.div`
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: img;
`

export const ImgWrap = styled.div`
	max-width: 555px;
	
	@media (max-width: 768px) {
		max-width: 100px;
	}
`
