import React from 'react'
import { MenuBar } from '../Line'
import {
	SidebarContainer,
	CloseIcon,
	SidebarWrapper,
	SidebarMenu,
	SidebarLink,
} from './SidebarElements'

const Sidebar = ({ isOpen, toggle }) => {
	return (
		<SidebarContainer isOpen={isOpen} onClick={toggle}>
			<CloseIcon onClick={toggle}>
				<MenuBar isOpen={isOpen} />
				<MenuBar isOpen={isOpen} />
			</CloseIcon>
			<SidebarWrapper>
				<SidebarMenu>
					<SidebarLink to='home' onClick={toggle}>Home</SidebarLink>
					<SidebarLink to='services' onClick={toggle}>Services</SidebarLink>
					<SidebarLink to='portfolio' onClick={toggle}>Portfolio</SidebarLink>
					<SidebarLink to='contact' onClick={toggle}>Contact Us</SidebarLink>
				</SidebarMenu>
			</SidebarWrapper>
		</SidebarContainer>
	)
}

export default Sidebar;
