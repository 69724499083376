import React from 'react'
import Video from '../../video/Hero.mp4'
import poster from '../../img/heroimg.png'
import { FcxOrange } from '../Svg'
import { Button } from '../Button'
import {
	HeroContainer,
	HeroBg,
	VideoBg,
	HeroBigLogo,
	HeroContent,
	HeroH1,
	HeroP,
} from './HeroElements'

const HeroSection = () => {
	return (
		<HeroContainer id='home'>
			<HeroBg>
				<VideoBg autoPlay loop muted src={Video} poster={poster} type='video/mp4' />
				<HeroBigLogo>
					<FcxOrange />
				</HeroBigLogo>
			</HeroBg>
			<HeroContent>
				<HeroH1>Modern Solutions for Modern Problems</HeroH1>
				<HeroP>
					We create intelligent solutions for web applications, softwares, cloud managements and analytics.
				</HeroP>
				<Button
					to='portfolio'
					smooth={true}
					duration={600}
					spy={true}
					exact='true'
					offset={-80}
				>Our Works</Button>
			</HeroContent>
		</HeroContainer>
	)
}

export default HeroSection;