import styled from 'styled-components'
import { Link as LinkScroll } from 'react-scroll';

export const SidebarContainer = styled.aside`
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100vh;
	background: ${({ theme }) => (theme.name === 'light') ? theme.accent : theme.bg};
	display: grid;
	align-items: center;
	top: 0;
	right: 0;
	transition: 0.2s ease-in-out;
	opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
	right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};	
`

export const CloseIcon = styled.div`
	position: absolute;
	top: 4rem;
	left: 2.5rem;
	min-width: 40px;
	font-size: 2rem;
	display: block;
	cursor: pointer;
`

export const SidebarWrapper = styled.div`
	color: #FF7302;
`

export const SidebarMenu = styled.ul`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(6, 80px);
	text-align: center;

	@media screen and (max-width: 480px) {
		grid-template-rows: repeat(6, 60px);
	}
`

export const SidebarLink = styled(LinkScroll)`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	text-decoration: none;
	list-style: none;
	transition: 0.2s ease-in-out;
	text-decoration: none;
	color:${({ theme }) => (theme.name === 'light') ? theme.bg : theme.accent};
	transition: 0.2s color ease-in-out;
	cursor: pointer;

	&:hover {
		color:${({ theme }) => (theme.name === 'light') ? '#222222' : theme.heading};
	}
`