const theme = {
	light: {
		name: 'light',
		accent: '#FF7302',
		heading: 'white',
		p: 'black',
		bg: 'white',
		navBg: 'rgba(255, 255, 255, 0.95)',
		techLogoBg: 'rgba(0, 0, 0, 1)',
		mixBlend: 'soft-light',
	},
	dark: {
		name: 'dark',
		accent: '#459989',
		heading: 'white',
		p: '#FFFFFF',
		bg: '#222222',
		navBg: 'rgba(34, 34, 34, 0.95)',
		techLogoBg: 'rgba(250, 250, 250, .98)',
		mixBlend: 'difference',
	},
	orangedark: {
		name: 'orangedark',
		accent: '#FF7302',
		heading: 'white',
		p: 'white',
		bg: '#222222',
		navBg: 'rgba(34, 34, 34, 0.95)',
		techLogoBg: 'rgba(250, 250, 250, .98)',
		mixBlend: 'darken',
	},
}

export default theme;