import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
	Aws,
	Mongo,
	MySql,
	NextJs,
	NodeJs,
	PostgreSql,
	Python,
	ReactLogo
} from './Svg'
import {
	TechLogosContainer,
	TechLogosWrapper,
	TechIcons,
	TechLogosDivFilter,
} from './TechLogosElements'

const logoKey = [1, 2, 3, 4, 5, 6, 7, 8]

const TechLogos = () => {
	//slider settings
	const settings = {
		swipeToSlide: false,
		lazyLoad: 'ondemand',
		speed: 3000,
		autoplaySpeed: 0,
		slidesToShow: 8,
		arrows: false,
		infinite: true,
		autoplay: true,
		centerMode: true,
		pauseOnHover: false,
		responsive: [
			{
				breakpoint: 5000,
				settings: {
					slidesToShow: 8,
				}
			},
			{
				breakpoint: 1920,
				settings: {
					slidesToShow: 7,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 512,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 280,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				}
			}
		]
		// variableWidth: true,
	};
	return (
		<TechLogosContainer>
			<TechLogosDivFilter>
			</TechLogosDivFilter>
			<TechLogosWrapper>
				<Slider {...settings}>
					{logoKey.map((item) => {
						return (
							<TechIcons key={item}>
								{(item === 1) && <Python />}
								{(item === 2) && <Mongo />}
								{(item === 3) && <MySql />}
								{(item === 4) && <PostgreSql />}
								{(item === 5) && <Aws />}
								{(item === 6) && <NextJs />}
								{(item === 7) && <ReactLogo />}
								{(item === 8) && <NodeJs />}
							</TechIcons>
						);
					})}
				</Slider>
			</TechLogosWrapper>
		</TechLogosContainer>
	);
}

export default TechLogos;