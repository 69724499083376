import React from 'react'
import { Line } from '../Line'
import { DevSvg, DataSvg, CloudSvg } from './Svg'
import {
	ServicesContainer,
	HeadingWrapper,
	ServicesWrapper,
	ServicesRow,
	TextCol,
	ImageCol,
	TextWrapper,
	TopLine,
	Heading,
	HSpan,
	ServiceTitle,
	ServiceP,
	ImgWrap,
} from './ServiceElements'
import { servicesObj } from './data'

const Services = () => {
	return (
		<ServicesContainer id='services'>
			<HeadingWrapper>
				<TextWrapper>
					<TopLine>WHAT ARE YOUR NEEDS</TopLine>
					<Line />
					<Heading><HSpan>FOURCOREX </HSpan>
							develops tailor-fit software applications and web-based solutions to streamline business processes.
						</Heading>
				</TextWrapper>
			</HeadingWrapper>
			<ServicesWrapper>

				{/* loop service rows */}
				{servicesObj.map(item => {
					return (
						<ServicesRow key={item.uid} imgStart={item.imgStart}>
							<TextCol>
								<ServiceTitle>{item.title}</ServiceTitle>
								<ServiceP>{item.description}</ServiceP>
							</TextCol>
							<ImageCol>
								<ImgWrap>
									{(item.title === 'Software Development') && <DevSvg />}
									{(item.title === 'Data Analytics') && <DataSvg />}
									{(item.title === 'Cloud Solutions') && <CloudSvg />}
								</ImgWrap>
							</ImageCol>
						</ServicesRow>
					);
				})}
			</ServicesWrapper>
		</ServicesContainer>
	)
}

export default Services;
