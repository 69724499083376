let keys = {}

//key ID for prod
if (process.env.NODE_ENV === 'production') {
	keys = {
		serviceId: 'service_juv5ozi',
		templateId: 'template_nxxld5r',
		userId: 'user_9GTUPrFMa75LoelYlbRQL',
		GRECAPTCHA_CLIENT_KEY: '6LcR_qcaAAAAABcIj3SYF8bgEj4yCGTkCTPstigj',
	}

	//key ID for dev
} else {
	keys = {
		serviceId: 'service_juv5ozi',
		templateId: 'template_rsuflma',
		userId: 'user_9GTUPrFMa75LoelYlbRQL',
		GRECAPTCHA_CLIENT_KEY: '6LeP56caAAAAANlrWoeaplTXX9sW9rIgC9S8bapF'
	}
}

export default keys