import styled, { css } from 'styled-components'

export const PortfolioContainer = styled.section`
	z-index: 1;
	position: relative;
	padding: 5em 0;
	background: ${({ theme }) => theme.bg};
	min-width: 240px;
	
`

export const PortfolioBg = styled.div`
	position:fixed;
	display:block;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;

	:before {
		content: '';
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: white;
		mix-blend-mode: ${({ theme }) => theme.mixBlend};
	}

	:after {
		content: '';
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		opacity: 0.8;
		background: #222;
		display: ${({ theme }) => (theme.name === 'orangedark') ? 'block' : 'none'};
	}
`

export const VideoBg = styled.video`
	object-fit: cover;
	min-width: 100%;
	min-height: 100vh;
	background: #000;
	background-size: cover;
`

export const HeadingDiv = styled.div`
	position: relative;
	z-index:2;
	left: 0;
	right: 0;
	margin-bottom: 2em;
	backdrop-filter: blur(10px);
	background: rgba(34, 34, 34, 0.74);
	transition: 500ms background ease-in-out;

	${({ theme }) =>
		(theme.name === 'light') &&
		css`
			background: rgba(255, 255, 255, 0.52);
	`}
`

export const TextWrapper = styled.div`
max-width: 540px;
padding: 2em 1em;
margin: auto;
text-align: center;
`