export const servicesObj = [
	{
		uid: 'item_service1',
		imgStart: false,
		title: 'Software Development',
		description: 'Bring life to your vision and provide great experience to your customers by developing intelligent and high-valued applications with us.'
	}, {
		uid: 'item_service2',
		imgStart: true,
		title: 'Data Analytics',
		description: 'Understand your customer\'s needs and anticipate the trends to create opportunities and quickly adapt to the business environment.'
	}, {
		uid: 'item_service3',
		imgStart: false,
		title: 'Cloud Solutions',
		description: 'Go online with scalable, reliable and highly available solutions. We scale your business in and out without maintaining data centers by utilizing secure cloud computing.'
	}
];