import styled from 'styled-components'
import { NavLogo } from '../Navbar/NavbarElements'
import { Link as LinkScroll } from 'react-scroll'

export const FooterContainer = styled.footer`
	transition: 300ms background ease-in-out;
	background: ${({ theme }) => (theme.name === 'dark') ? 'rgba(50, 50, 50, 0.98)' : 'rgba(100, 100, 100, 0.88)'};
	position: relative;
	z-index: 2;
`

export const FooterContent = styled.article`
	display: grid;
	justify-content: space-evenly;
	padding: 2em 0;

	@media (min-width: 768px ) {
		grid-template-columns: auto auto auto;
	}
`

export const FooterCol = styled.div`
	padding: 1em;
`

export const FooterTitle = styled.h4`
	padding: 0.5em 0;
	color: white;
	font-size: calc(0.2vmin + 1.2rem);
`

export const FooterLinks = styled(LinkScroll).attrs({
	smooth: true,
	duration: 450,
	spy: true,
	exact: 'true',
})`
	text-decoration: none;
	color: #E5E5E5;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	padding: 0.1em 0;
	font-size: calc(0.2vmin + 1rem);
	transition: 100ms color ease-in-out;

	&:hover {
		color: ${({ theme }) => theme.accent};
	}
`

export const FooterBottom = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 3em 0;

	@media (min-width: 768px){
	flex-direction: row;
	justify-content: space-evenly;
	}
`

export const Logo = styled(NavLogo)`
	margin: auto;
	
	@media (min-width: 768px){
		margin-right: 0;
		margin-left: auto;
	}
`

export const FooterRights = styled.p`
	position: relative;
	color: white;
	font-style: normal;
	text-align: center;
	font-size: 1rem;
	line-height: 2rem;
	margin: auto;
`

//div for social media links
export const SocMedDiv = styled.div`
	
`