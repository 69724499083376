import styled from 'styled-components'

export const TechLogosDivFilter = styled.div`
	position:absolute;
	display:block;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;

	:before {
		content: '';
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		opacity:90%;
		background: ${({ theme }) => theme.techLogoBg};
		transition: 500ms background ease-in-out;
		mix-blend-mode: difference;
	}
`

export const TechLogosContainer = styled.section`
	z-index: 2;
	position: relative;
	transition: 500ms background ease-in-out;
	opacity: 90%;
`

export const TechLogosWrapper = styled.div`
	padding: 1em 0em;
	background: rgba(255, 255, 255, 1);
`

export const TechIcons = styled.div`
	padding: 0 0.55em;
	transition: 200ms transform ease-in-out;
	
	@media (min-width: 768px){
		padding: 0 0.8em;
	}
	@media (min-width: 1080px){
		padding: 0 2em;
	}
`