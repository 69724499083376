import styled from 'styled-components'

export const NavLogo = styled.svg`

	#logoText path{
		transition: 800ms fill ease-in-out;
		fill: ${({ scrollNav, theme }) => (scrollNav ? theme.accent : '#FFFFFF')};
				
		&:hover {
			fill: ${({ theme }) => ((theme.accent === '#FF7302') ? '#459989' : '#FF7302')};
		}
  }

	#logoOrange path {
		transition: 800ms opacity ease-in-out;
		opacity: ${({ theme }) => ((theme.accent === '#FF7302') ? '1' : '0')};
	}

	#logoGreen path {
		transition: 800ms opacity ease-in-out;
		opacity: ${({ theme }) => ((theme.accent === '#FF7302') ? '0' : '1')};
	}
`

export const HeroIcon = styled.svg`

	/* targets logoGreen SVG */
	#logoGreen path {
		transition: 800ms opacity ease-in-out;
		opacity: ${({ theme }) => ((theme.accent === '#FF7302') ? '0' : '1')};
	}

	/* targets logoOrange SVG */
	#logoOrange path {
		transition: 800ms opacity ease-in-out;
		opacity: ${({ theme }) => ((theme.accent === '#FF7302') ? '1' : '0')};
	}
`