import { CardSvg } from './CardElements'

export const CityIcon = () => {
	return (
		<CardSvg width="100%" height="100%" viewBox="0 0 687 502" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="cityIcon">
				<g id="accent">
					<path id="Vector" opacity="0.1" d="M343.725 501.483C533.262 501.483 686.912 494.204 686.912 485.225C686.912 476.246 533.262 468.967 343.725 468.967C154.187 468.967 0.537109 476.246 0.537109 485.225C0.537109 494.204 154.187 501.483 343.725 501.483Z" fill="#FF7302" />
					<path id="Vector_2" d="M533.581 21.342V60.201H482.991V483.988H588.571V76.332V60.201V21.342H533.581Z" fill="#FF7302" />
					<path id="Vector_3" d="M254.967 282.359V215.639H190.446V282.359H166.251V484.721H278.429V282.359H254.967Z" fill="#FF7302" />
					<path id="Vector_4" d="M265.965 390.872H178.715V485.454H265.965V390.872Z" fill="#FF7302" />
					<path id="Vector_5" d="M445.598 0.07901V22.808H316.555V0.07901H278.429V485.454H482.991V0.07901H445.598Z" fill="#FF7302" />
					<path id="Vector_6" d="M132.524 138.653V120.323H113.461V138.653H78.9998V485.454H166.251V138.653H132.524Z" fill="#FF7302" />
					<path id="Vector_26" d="M612.766 226.636V146.718H561.442V226.636H543.112V485.454H630.363V226.636H612.766Z" fill="#FF7302" />
				</g>
				<path id="Vector_7" opacity="0.1" d="M307.024 0.0793457H278.429V485.454H307.024V0.0793457Z" fill="black" />
				<path id="Vector_8" opacity="0.1" d="M192.645 390.872H178.715V485.454H192.645V390.872Z" fill="black" />
				<path id="Vector_9" opacity="0.1" d="M474.192 0.0793457H445.597V22.8084H474.192V0.0793457Z" fill="black" />
				<path id="Vector_10" opacity="0.1" d="M123.725 120.323H113.46V138.653H123.725V120.323Z" fill="black" />
				<path id="Vector_11" opacity="0.1" d="M89.265 138.653H79.0002V485.454H89.265V138.653Z" fill="black" />
				<path id="Vector_12" opacity="0.1" d="M474 62H446V485H474V62Z" fill="black" />
				<path id="Vector_13" opacity="0.1" d="M166.25 138.653H139.855V202.441H166.25V138.653Z" fill="black" />
				<g id="windows">
					<path id="Vector_14" d="M351.015 61.6677H309.957V79.2644H351.015V61.6677Z" fill="white" />
					<path id="Vector_15" d="M438.265 242.767H397.207V260.363H438.265V242.767Z" fill="white" />
					<path id="Vector_16" d="M351.015 369.609H309.957V387.206H351.015V369.609Z" fill="white" />
					<path id="Vector_17" d="M143.521 217.838H102.462V235.435H143.521V217.838Z" fill="white" />
					<path id="Vector_18" d="M243.236 401.137H202.177V418.733H243.236V401.137Z" fill="white" />

					<path id="Vector_19" opacity="0.1" d="M257.9 297.756H187.513V327.084H257.9V297.756Z" fill="white" />
					<path id="Vector_20" opacity="0.1" d="M565.841 93.9282H495.455V123.256H565.841V93.9282Z" fill="white" />
					<path id="Vector_21" opacity="0.1" d="M565.841 156.25H495.455V185.578H565.841V156.25Z" fill="white" />
					<path id="Vector_22" opacity="0.1" d="M565.841 218.571H495.455V247.899H565.841V218.571Z" fill="white" />
					<path id="Vector_23" opacity="0.1" d="M565.841 280.893H495.455V310.221H565.841V280.893Z" fill="white" />
					<path id="Vector_24" opacity="0.1" d="M565.841 343.214H495.455V372.542H565.841V343.214Z" fill="white" />
					<path id="Vector_25" opacity="0.1" d="M565.841 405.536H495.455V434.864H565.841V405.536Z" fill="white" />
					<path id="Vector_29" opacity="0.1" d="M630.362 356.412H587.104V382.074H630.362V356.412Z" fill="white" />
					<path id="Vector_30" opacity="0.1" d="M629.629 318.286H588.57V335.882H629.629V318.286Z" fill="white" />
				</g>

				<path id="Vector_27" opacity="0.1" d="M577.572 146.718H561.442V226.636H577.572V146.718Z" fill="black" />
				<path id="Vector_28" opacity="0.1" d="M559.243 226.636H543.112V485.454H559.243V226.636Z" fill="black" />
			</g>
		</CardSvg>
	);
}

export const ExamIcon = () => {
	return (
		<CardSvg width="100%" height="100%" viewBox="0 0 687 502" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="exam">
				<g id="accent">
					<path id="Vector" d="M312.427 287.239C312.095 287.239 311.768 287.218 311.446 287.182C311.366 287.173 311.286 287.157 311.206 287.146C310.957 287.112 310.71 287.071 310.468 287.017C310.378 286.998 310.289 286.972 310.199 286.948C309.965 286.889 309.735 286.821 309.509 286.744C309.418 286.713 309.328 286.679 309.239 286.645C309.011 286.558 308.789 286.463 308.57 286.358C308.517 286.333 308.463 286.316 308.41 286.29L254.465 259.318C251.419 257.795 249.495 254.681 249.495 251.275C249.495 247.87 251.419 244.757 254.465 243.234L308.41 216.261C308.456 216.239 308.505 216.224 308.552 216.202C308.807 216.08 309.068 215.969 309.336 215.871C309.413 215.842 309.489 215.813 309.567 215.787C309.89 215.678 310.221 215.583 310.56 215.511C310.567 215.509 310.575 215.507 310.583 215.505C310.922 215.434 311.271 215.386 311.624 215.354C311.726 215.345 311.829 215.344 311.931 215.338C312.098 215.329 312.261 215.313 312.43 215.313H519.219V125.404H510.228H442.797H433.806H423.017V31H204.541C199.575 31 195.55 35.0252 195.55 39.9908V462.56C195.55 467.525 199.575 471.551 204.541 471.551H510.229C515.195 471.551 519.22 467.525 519.22 462.56V287.239H312.431C312.429 287.239 312.428 287.239 312.427 287.239ZM240.504 80.4496H294.449V98.4312H240.504V80.4496ZM240.504 116.413H357.385V134.395H240.504V116.413Z" fill="black" />
					<path id="Vector_2" d="M442.798 36.266V105.624H512.156L442.798 36.266Z" fill="black" />
					<path id="Vector_3" d="M533.173 233.322H507.533V269.424H533.173C536.392 269.424 539 266.816 539 263.597V239.148C539 235.931 536.392 233.322 533.173 233.322Z" fill="black" />
					<path id="Vector_4" d="M312.733 233.397C312.526 233.464 312.326 233.544 312.13 233.633C312.109 233.643 312.087 233.649 312.067 233.659L285.702 245.962C283.637 246.926 282.317 248.998 282.317 251.276C282.317 253.554 283.637 255.627 285.702 256.591L312.067 268.893C312.089 268.904 312.114 268.911 312.137 268.922C312.274 268.983 312.414 269.038 312.556 269.09C312.609 269.108 312.66 269.129 312.713 269.147C312.842 269.189 312.974 269.225 313.106 269.258V233.294C312.992 233.322 312.878 233.349 312.766 233.385C312.756 233.388 312.745 233.393 312.733 233.397Z" fill="black" />
					<path id="Vector_5" d="M489.586 233.294H330.503V269.257H489.586V233.294Z" fill="black" />
				</g>
			</g>
		</CardSvg>

	);
}

export const TicketIcon = () => {
	return (
		<CardSvg width="100%" height="100%" viewBox="0 0 687 502" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="ticketIcon">
				<g id="accent">
					<path id="XMLID 461" d="M624 103.717V67H477.13V434.174H624V397.456C604.417 397.456 587.283 381.018 587.283 360.739C587.283 340.46 604.417 324.022 624 324.022V287.304C604.417 287.304 587.283 270.866 587.283 250.587C587.283 230.308 604.417 213.87 624 213.87V177.152C604.417 177.152 587.283 160.714 587.283 140.435C587.283 120.156 604.417 103.717 624 103.717Z" fill="black" />
					<path id="XMLID 462" d="M61 103.717C81.8065 103.717 97.7174 120.156 97.7174 140.435C97.7174 160.714 81.8065 177.152 61 177.152V213.87C81.8065 213.87 97.7174 230.308 97.7174 250.587C97.7174 270.866 81.8065 287.304 61 287.304V324.022C81.8065 324.022 97.7174 340.46 97.7174 360.739C97.7174 381.018 81.8065 397.456 61 397.456V434.174H440.413V67H61V103.717Z" fill="black" />
				</g>
			</g>
		</CardSvg>

	);
}

export const FinancialIcon = () => {
	return (
		<CardSvg width="100%" height="100%" viewBox="0 0 687 502" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="financialIcon">
				<g id="accent">
					<path id="Vector" d="M388.577 302.159C378.758 293.212 370.889 282.172 365.668 269.732H200.513C189.241 269.732 180.481 260.634 180.481 249.353V106.03C180.481 94.7486 189.241 85.4634 200.513 85.4634H429.407C440.697 85.4634 450.17 94.7486 450.17 106.03V142.155C450.303 142.155 450.392 142.155 450.517 142.155C462.957 142.155 474.844 144.65 485.688 149.15V106.03C485.688 74.7169 460.702 49 429.407 49H200.513C169.2 49 144 74.7258 144 106.03V249.371C144 280.675 169.209 306.213 200.513 306.213H234.463C236.433 322.538 250.717 335.976 268.084 335.976H352.809C353.861 334.461 354.939 332.964 356.062 331.502C365.151 319.615 376.191 309.724 388.577 302.159Z" fill="black" />
					<path id="Vector_2" d="M314.367 253.719C319.402 253.719 323.483 249.629 323.483 244.594V234.712C344.593 230.987 356.587 216.828 356.587 200.236C356.587 183.474 347.56 173.227 325.408 165.394C309.573 159.442 303.042 155.53 303.042 149.372C303.042 144.16 306.954 138.938 319.072 138.938C326.121 138.938 331.833 140.123 336.359 141.54C338.89 142.333 341.635 142.048 343.952 140.764C346.268 139.481 347.944 137.298 348.594 134.741L349.164 132.522C350.465 127.478 347.578 122.31 342.588 120.858C337.678 119.441 332.109 118.398 324.437 118.068V110.806C324.437 105.771 320.355 101.681 315.321 101.681C310.286 101.681 306.196 105.762 306.196 110.806V119.378C286.049 123.281 274.625 136.149 274.625 152.545C274.625 170.616 288.187 179.928 308.121 186.638C321.933 191.307 327.858 195.771 327.858 202.847C327.858 210.305 320.587 214.395 309.956 214.395C302.275 214.395 295.049 212.827 288.793 210.581C286.236 209.672 283.411 209.86 281.014 211.134C278.599 212.408 276.852 214.627 276.175 217.265L275.445 220.152C274.179 224.999 276.951 229.998 281.691 231.656C288.374 233.972 296.617 235.639 305.243 236.022V244.594C305.243 249.629 309.324 253.719 314.367 253.719Z" fill="black" />
					<path id="Vector_3" d="M541.453 391.597C534.725 341.384 488.602 306.106 438.398 312.807C388.176 319.49 352.907 365.631 359.599 415.844L360.018 419.069C362.673 438.976 379.666 454 399.752 454H501.3C521.394 454 538.387 438.976 541.043 419.069L541.462 415.915C542.442 408.207 542.513 399.688 541.453 391.597Z" fill="black" />
					<path id="Vector_4" d="M450.517 299.013C486.325 299.013 515.353 269.985 515.353 234.178C515.353 198.37 486.325 169.342 450.517 169.342C414.709 169.342 385.681 198.37 385.681 234.178C385.681 269.985 414.709 299.013 450.517 299.013Z" fill="black" />
				</g>
			</g>
		</CardSvg>

	);
}

export const EnrollmentIcon = () => {
	return (
		<CardSvg width="100%" height="100%" viewBox="0 0 687 502" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="enrollmentIcon">
				<g id="accent">
					<g id="firstList">
						<path id="Vector" d="M482.362 325.285L453.922 224.33C451.852 216.981 456.147 209.318 463.495 207.248C470.844 205.178 478.507 209.473 480.577 216.821L509.017 317.777C511.087 325.125 506.792 332.788 499.444 334.858C492.095 336.929 484.432 332.634 482.362 325.285ZM473.914 218.699C472.878 215.024 469.047 212.877 465.373 213.912C461.698 214.947 459.551 218.779 460.586 222.453L489.026 323.408C490.061 327.083 493.892 329.23 497.566 328.195C501.241 327.16 503.388 323.328 502.353 319.654L473.914 218.699Z" fill="black" />
						<path id="Vector_2" d="M443.931 187.586C445.953 194.762 453.409 198.941 460.586 196.92C467.762 194.898 471.941 187.441 469.919 180.265C467.898 173.088 460.441 168.909 453.265 170.931C446.088 172.953 441.909 180.409 443.931 187.586Z" fill="black" />
						<path id="Vector_3" d="M440.599 188.524C438.063 179.522 443.324 170.135 452.326 167.599C461.328 165.063 470.715 170.324 473.251 179.326C475.787 188.328 470.527 197.715 461.524 200.251C452.522 202.787 443.135 197.527 440.599 188.524ZM466.588 181.203C465.087 175.875 459.531 172.762 454.203 174.263C448.875 175.764 445.762 181.319 447.263 186.647C448.764 191.975 454.319 195.089 459.647 193.588C464.975 192.087 468.089 186.531 466.588 181.203Z" fill="black" />
						<path id="Vector_4" d="M485.984 297.293L469.934 240.318C469.416 238.478 470.487 236.566 472.328 236.048C474.168 235.529 476.08 236.601 476.598 238.441L492.648 295.416C493.166 297.256 492.095 299.168 490.255 299.686C488.415 300.205 486.503 299.133 485.984 297.293Z" fill="black" />
						<path id="Vector_5" d="M466.18 226.991L464.49 220.993C463.972 219.153 465.044 217.241 466.884 216.723C468.724 216.205 470.636 217.276 471.154 219.116L472.844 225.114C473.362 226.954 472.291 228.866 470.45 229.384C468.61 229.902 466.698 228.831 466.18 226.991Z" fill="black" />
					</g>
					<path id="Vector_6" d="M539.994 375.999L578 365.223L503.34 101.698L348.306 145.629L300.209 25H199.669L257.32 171.396L108 213.7L182.676 477.226L321.336 437.949L309.405 400.186L237.447 420.565L184.353 233.232L271.888 208.426L325.796 345.381L309.568 368.43L325.226 407.006L353.352 477.226L391.048 462.137L377.474 428.215L380.761 428.557L400.261 477.226L442.091 460.493L431.284 433.538L434.36 433.831L441.456 451.312L452.004 477.258L494.436 460.021L483.922 434.075L476.793 416.594L475.784 414.087L469.045 397.517L465.806 389.541L461.395 378.7L471.112 387.409L496.992 412.378L537.048 396.28L497.529 358.989L497.659 358.956L493.297 355.083L478.388 341.833L475.914 339.587V339.603L464.862 329.772L458.368 324.026L435.662 306.626L411.589 304.656L362.971 182.627L475.963 150.61L529.04 337.975L501.614 345.756L539.994 375.999ZM192.149 338.219C194.135 345.251 190.082 352.543 183.067 354.513C176.052 356.499 168.744 352.446 166.758 345.414C164.772 338.382 168.858 331.106 175.873 329.104C182.888 327.135 190.18 331.204 192.149 338.219Z" fill="black" />
					<g id="list">
						<path id="Vector_7" d="M423.661 342.549L395.222 241.593C393.152 234.245 397.446 226.582 404.795 224.511C412.143 222.441 419.806 226.736 421.877 234.085L450.316 335.04C452.386 342.389 448.092 350.051 440.743 352.122C433.394 354.192 425.731 349.897 423.661 342.549ZM415.213 235.962C414.178 232.287 410.346 230.14 406.672 231.175C402.998 232.21 400.85 236.042 401.885 239.716L430.325 340.671C431.36 344.346 435.191 346.493 438.866 345.458C442.54 344.423 444.687 340.591 443.652 336.917L415.213 235.962Z" fill="black" />
						<path id="Vector_8" d="M381.898 205.788C379.362 196.785 384.623 187.398 393.625 184.862C402.628 182.326 412.015 187.587 414.551 196.589C417.087 205.592 411.826 214.979 402.824 217.515C393.821 220.051 384.434 214.79 381.898 205.788ZM407.887 198.466C406.386 193.139 400.831 190.025 395.503 191.526C390.175 193.027 387.061 198.582 388.562 203.91C390.063 209.238 395.619 212.352 400.946 210.851C406.274 209.35 409.388 203.794 407.887 198.466Z" fill="black" />
						<path id="Vector_9" d="M263.362 387.705L234.922 286.75C232.852 279.401 237.147 271.738 244.495 269.668C251.844 267.598 259.507 271.893 261.577 279.241L290.017 380.197C292.087 387.545 287.792 395.208 280.444 397.279C273.095 399.349 265.432 395.054 263.362 387.705ZM254.913 281.119C253.878 277.444 250.047 275.297 246.373 276.332C242.698 277.367 240.551 281.199 241.586 284.873L270.026 385.828C271.061 389.503 274.892 391.65 278.566 390.615C282.241 389.58 284.388 385.748 283.353 382.074L254.913 281.119Z" fill="black" />
						<path id="Vector_10" d="M221.599 250.945C219.063 241.942 224.324 232.555 233.326 230.019C242.328 227.483 251.715 232.744 254.251 241.746C256.787 250.749 251.527 260.136 242.524 262.672C233.522 265.208 224.135 259.947 221.599 250.945ZM247.588 243.623C246.087 238.295 240.531 235.182 235.203 236.683C229.875 238.184 226.762 243.739 228.263 249.067C229.764 254.395 235.319 257.509 240.647 256.008C245.975 254.507 249.088 248.951 247.588 243.623Z" fill="black" />
					</g>
				</g>
			</g>
		</CardSvg>

	);
}