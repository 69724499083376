export const projectsObj = [
	{
		uid: 'item_proj1',
		index: 0,
		icon: 'ticketIcon',
		h2: 'Ticketing System',
		desc: 'This application is created to monitor all internal and external transactions for a school. It allows creation of unique forms depending on the requirement of a campus department. Reports for transaction status and staff performance matrix can be viewed on-demand.',
		ariaLabel: 'Ticketing System'
	},
	{
		uid: 'item_proj2',
		index: 1,
		icon: 'examIcon',
		h2: 'Examination Module',
		desc: 'This application was made to assist schools in providing and organizing exams for students. Instructors/teachers are no longer required to print exam sheets and students will take exams via terminal with a supported web browser. Exam results can be viewed by the instructor online or be printed for hard-copy reports.',
		ariaLabel: 'Examination Module'
	}, {
		uid: 'item_proj3',
		index: 2,
		icon: 'cityIcon',
		h2: 'Smart City (Paperless Initiative)',
		desc: 'This project aims to provide a solution towards a digitally integrated and paperless services for government agencies and private businesses thru a platform of smart forms.',
		ariaLabel: 'Smart City'
	},

	{
		uid: 'item_proj4',
		icon: 'enrollmentIcon',
		h2: 'Enrollment System',
		desc: 'A customized system specific to our client\'s need that provides an easy-to-use platform both for students and school faculties during the entire enrollment process. Students can register on the system and have their personal dashboard that tracks where they are on the enrollment process, payment history and their pending document requirements. ',
		index: 3,
		ariaLabel: 'Enrollment System'
	},
	{
		uid: 'item_proj5',
		icon: 'financialIcon',
		h2: 'Financial Management System with Payroll',
		desc: 'A cloud-based system developed for a Government agency as a financial accounting solution designed to streamline their existing manual process. This project also includes an online payroll system where the employees can login and get the detailed history of their compensation.',
		index: 4,
		ariaLabel: 'Financial Management'
	},
]