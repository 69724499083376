import styled, { css } from 'styled-components';

export const ColorDiv = styled.span`
	background: ${({ theme }) => theme.accent};
	min-width: 240px;
	height:20px;
	display:block;
	transition: 500ms background ease-in-out;
`

export const Line = styled.span`
	font-size: 20px;
	padding: 0.3em 0;
	margin-bottom: 0.4em;
	border-bottom: 2px solid ${({ theme }) => theme.p};
	display: block;
	transition: 1000ms border ease-in-out;
`

export const MenuBar = styled.span`
	display: block;
	width: auto;
	height: 2.5px;
	background: ${({ scrollNav, theme }) => scrollNav ? theme.p : theme.heading};
	transition: 800ms transform ease;

	&:not(:last-child) {
		margin-bottom: 0.35em;
	}

	${({ isOpen }) =>
		isOpen &&
		css`
		transform-origin: 14px 1px;
		transform: rotate(45deg);
		
			&:last-child {
			transform: rotate(-45deg);
			}
		`}
`