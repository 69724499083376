import React from 'react'
import { toggleHome } from '../../utils'
import { FcxLogoFull } from '../Svg'
import {
	FooterContainer,
	FooterContent,
	FooterCol,
	FooterTitle,
	FooterLinks,
	FooterBottom,
	Logo,
	FooterRights,
	SocMedDiv,
} from './FooterElements'

const Footer = () => {
	return (
		<FooterContainer>
			<FooterContent>
				<FooterCol>
					<FooterTitle>Company</FooterTitle>
					<FooterLinks to="home" >About Us</FooterLinks>
					<FooterLinks to="portfolio" >Our Works</FooterLinks>
				</FooterCol>
				<FooterCol>
					<FooterTitle>Services</FooterTitle>
					<FooterLinks to="services" offset={-80} >Software Development</FooterLinks>
					<FooterLinks to="services" offset={300} >Data Analytics</FooterLinks>
					<FooterLinks to="services" offset={300} >Cloud Solutions</FooterLinks>
				</FooterCol>
				<FooterCol>
					<FooterTitle>Contact</FooterTitle>
					<FooterLinks to="contact" >contact@fourcorex.com</FooterLinks>
					<FooterLinks to="contact">Office</FooterLinks>
				</FooterCol>
			</FooterContent>
			<FooterBottom>
				<Logo to='/' onClick={toggleHome} >
					<FcxLogoFull />
				</Logo>
				<FooterRights>FOURCOREX © {new Date().getFullYear()} All rights reserved</FooterRights>
				<SocMedDiv></SocMedDiv>
			</FooterBottom>
		</FooterContainer>
	)
}

export default Footer;