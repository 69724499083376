import React, { useState, useEffect } from 'react'
import { toggleHome } from '../../utils'
import { FcxLogoFull } from '../Svg'
import { MenuBar } from '../Line'
import {
	Nav,
	NavbarContainer,
	NavLogo,
	NavLineBar,
	NavMenu,
	NavItem,
	NavLinks,
} from './NavbarElements'

const Navbar = ({ toggle, toggleTheme }) => {
	const [scrollNav, setScrollNav] = useState(false)

	const changeNav = () => {
		if (window.scrollY >= 80) return setScrollNav(true)
		setScrollNav(false)
	}

	useEffect(() => {
		window.addEventListener('scroll', changeNav)
	}, [])

	const logoClick = () => {
		toggleTheme()
	}

	return (
		<Nav scrollNav={scrollNav}>
			<NavbarContainer >
				<NavLogo to='/' onClick={logoClick}>
					<FcxLogoFull scrollNav={scrollNav} />
				</NavLogo>
				<NavLineBar onClick={toggle} >
					<MenuBar scrollNav={scrollNav}></MenuBar>
					<MenuBar scrollNav={scrollNav}></MenuBar>
					<MenuBar scrollNav={scrollNav}></MenuBar>
				</NavLineBar>
				<NavMenu>
					<NavItem>
						<NavLinks
							to='/'
							onClick={toggleHome}>Home</NavLinks>
					</NavItem>
					<NavItem>
						<NavLinks to='services' >Services</NavLinks>
					</NavItem>
					<NavItem>
						<NavLinks to='portfolio' offset={-80} >Portfolio</NavLinks>
					</NavItem>
					<NavItem>
						<NavLinks to='contact' >Contact Us</NavLinks>
					</NavItem>
				</NavMenu>
			</NavbarContainer>
		</Nav>
	)
}

export default Navbar;
