import React from 'react'
import Video from '../../video/Portfolio.mp4'
import poster from '../../img/portfolioBG.png'
import { Line } from '../Line'
import { TopLine, HSpan, Heading } from '../Services/ServiceElements'
import Card from '../Card/index';
import {
	PortfolioContainer,
	PortfolioBg,
	VideoBg,
	HeadingDiv,
	TextWrapper,
} from './PortfolioElements'

const Portfolio = () => {
	return (
		<PortfolioContainer id='portfolio'>
			<PortfolioBg>
				<VideoBg autoPlay loop muted src={Video} poster={poster} type='video/mp4' />
			</PortfolioBg>
			<article>
				<HeadingDiv>
					<TextWrapper>
						<TopLine>Intelligence by Design</TopLine>
						<Line />
						<Heading>
							<HSpan style={{ textTransform: 'uppercase' }}>Our Works </HSpan>
						</Heading>
					</TextWrapper>
				</HeadingDiv>
				<Card />
			</article>
		</PortfolioContainer>
	)
}

export default Portfolio;
